<template>
  <div class="check-homework">
    <div class="table-header-tabs">
      <div
        v-for="(tab, index) in headerTabs"
        :key="tab"
        class="header-tab"
        :class="{ 'header-tab--active': activeTabIndex === index }"
        @click="activeTabIndex = index"
      >
        <p>{{ tab }}</p>
      </div>
    </div>

    <div class="table-header-actions">
      <div class="top">
        <SearchInput
          id="statistic-search"
          :placeholder="$t('label.enterEmailOrFioOrTask')"
          :value.sync="searchPromt"
        />
      </div>
      <div class="bottom">
        <HomeworksFilter
          :showStatusFilter="activeTabIndex !== 0"
          showDateFilter
          showSort
          @filterChange="handleFilterChange"
        />
        <TaskDropdown v-if="isChatsLoaded" />
      </div>
    </div>

    <b-table
      ref="table"
      class="lms-table manage-students table-homework scroll"
      id="table-homework"
      :items="filteredUsers"
      :fields="fields"
      primary-key="username"
      borderless
      responsive
      :tbody-transition-props="{ name: 'flip-list' }"
      stacked="sm"
      :current-page="currentPage"
      :per-page="perPage"
    >
      <template #cell(full_name)="data">
        <div>
          {{ data.item.userFullName }}
        </div>
      </template>

      <template #cell(username)="data">
        <div>
          {{ data.item.email }}
        </div>
      </template>

      <template #cell(updated_at)="data">
        <div>
          {{ formatDateCreation(data.item.updated_at) }}
        </div>
      </template>

      <template #cell(taskTitle)="data">
        <div class="task-title">
          {{ data.item.taskTitle }}
        </div>
      </template>

      <template #cell(status)="data">
        <div>
          <ProgressStatus iconPosition="left" :status="computeStatus(data.item)" />
        </div>
      </template>

      <template #cell(access)="data">
        <router-link :to="{ path: '/check-homeworks', query: { homeworkId: data.item.chatId } }">
          <AddButton :title="$t('buttonLabels.check')" size="small" variant="secondary"
        /></router-link>
      </template>

      <template #custom-foot>
        <tr role="row" ref="footer">
          <th
            colspan="8"
            class="table-footer"
            :class="{ 'table-footer--invisible': isChatsLoaded }"
          >
            <tr>
              <td>{{ $t('label.loading') }}</td>
            </tr>
          </th>
        </tr>
      </template>
    </b-table>
    <TablePagination
      v-show="filteredUsers.length > 20"
      :length="filteredUsers.length"
      @goToPage="goToPage"
      @setPerPage="setItemsPerPage"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddButton from '@/components/Buttons/AddButton.vue';
import ProgressStatus from '@/components/ProgressStatus.vue';
import TablePagination from '@/components/TablePagination.vue';
import paginationMixin from '@/mixins/pagination.mixin';
import homeworkFilterMixin from '@/mixins/homeworkFilter.mixin';
import TaskDropdown from '@/components/TaskDropdown.vue';
import HomeworksFilter from './HomeworksFilter.vue';
import SearchInput from './SearchInput.vue';
import { ActivityStatuses } from '../utils/statusConstants';

export default {
  name: 'TableHomeworks',
  components: {
    ProgressStatus,
    AddButton,
    TablePagination,
    HomeworksFilter,
    TaskDropdown,
    SearchInput,
  },
  mixins: [paginationMixin, homeworkFilterMixin],
  async created() {
    await this.fetchChats();
  },
  data() {
    return {
      selected: [],
      allSelected: false,
      isRequestBusy: false,
      observer: null,
      isAllStudentsLoaded: false,
      activeTabIndex: 0,
      currentFilter: null,
      searchPromt: '',
      fields: [
        {
          key: 'full_name',
          label: this.$t('label.user'),
          class: 'user-name-column',
        },
        {
          key: 'username',
          label: 'E-mail',
          class: 'email-column',
        },
        {
          key: 'taskTitle',
          label: this.$t('label.task'),
          class: 'date-subscribe-column',
        },
        {
          key: 'updated_at',
          label: this.$t('label.sent'),
          class: 'date-subscribe-column',
        },

        {
          key: 'status',
          label: this.$t('label.status'),
          class: 'date-subscribe-column',
        },
        {
          key: 'access',
          label: '',
          class: 'access-column',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ chats: 'chats/getChatList' }),
    headerTabs() {
      return [
        this.$t('label.needToBeChecked'),
        this.$t('label.checked'),
        this.$t('label.allTasks'),
      ];
    },

    isChatsLoaded() {
      return !!this.chats?.length;
    },
    allSelectedUsersId() {
      return this.users.map((u) => u._id);
    },
    users() {
      const userChats = [];
      if (this.chats?.length) {
        this.chats.forEach((value) => {
          const homework = {};
          homework.userFullName = `${value.User.firstName.trim()} ${value.User.lastName.trim()}`;
          homework.email = value.User.username;
          homework.taskTitle = value.Task.name;
          homework.status = value.Statistics.status;
          homework.updated_at = value.LastMessageSentAt;
          homework.chatId = value.TaskChatID;
          userChats.push(homework);
        });
      }
      return userChats;
    },
    // eslint-disable-next-line vue/no-async-in-computed-properties
    filteredUsers() {
      let filteredUsers = this.users;
      if (this.activeTabIndex === 0) {
        filteredUsers = filteredUsers.filter((t) => t?.status === ActivityStatuses.doing);
      } else if (this.activeTabIndex === 1) {
        filteredUsers = filteredUsers.filter(
          (t) => t?.status === ActivityStatuses.rejected || t?.status === ActivityStatuses.done,
        );
      }

      if (!this.currentFilter && !this.searchPromt) return filteredUsers;

      if (this.searchPromt) {
        // eslint-disable-next-line  max-len
        filteredUsers = filteredUsers.filter((item) => (this.checkSearchMatch(item, this.searchPromt)));
      }

      if (this.currentFilter?.statusFilter) {
        // eslint-disable-next-line  max-len
        filteredUsers = filteredUsers.filter((item) => this.checkStatusFilterMatch(item.status, this.currentFilter.statusFilter));
      }

      if (this.currentFilter?.dateRange) {
        // eslint-disable-next-line  max-len
        filteredUsers = filteredUsers.filter((item) => this.checkDateRangeFilterMatch(item.updated_at, this.currentFilter.dateRange));
      }

      if (this.currentFilter?.selectedSort) {
        filteredUsers = this.sortHomeworks(filteredUsers, this.currentFilter.selectedSort);
      }
      return filteredUsers;
    },
  },
  methods: {
    ...mapActions('chats', ['fetchChats']),
    formatDateCreation(date) {
      const timeToSet = new Date(date);
      return new Intl.DateTimeFormat(this.$i18n?.locale === 'en' ? 'en' : 'ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(timeToSet);
    },
    computeStatus(userData) {
      if (userData?.status === ActivityStatuses.done) {
        return {
          value: 'done',
          label: 'label.accepted',
        };
      }
      if (userData?.status === ActivityStatuses.rejected) {
        return {
          value: 'rejected',
          label: 'label.rejected',
        };
      }
      return {
        value: 'not-started',
        label: 'label.onTheCheck',
      };
    },
    handleFilterChange(e) {
      this.currentFilter = e;
    },
    checkSearchMatch(chat, searchPromt) {
      return (
        chat.email.includes(searchPromt.toLowerCase())
        || chat.userFullName.toLowerCase().includes(searchPromt.toLowerCase())
        || chat.taskTitle.toLowerCase().includes(searchPromt.toLowerCase())
      );
    },
  },
  watch: {
    filteredUsers: {
      handler() {
        this.$refs.table.refresh();
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.observer?.unobserve) {
      this.observer.unobserve(this.$refs.footer);
    }
  },
};
</script>

<style scoped>
::v-deep .progress-status {
  justify-content: start;
}
</style>
